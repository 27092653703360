<!--
 * @Description: 企业详情
 * @Author: huacong
 * @Date: 2021-07-08 13:36:46
 * @LastEditTime: 2021-07-30 11:48:50
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <div class="topboxShadow">
      <section>
        <div class="resumeTitle clearfix">
          <div class="clearfix">
            <div class="block talentAvd">
              <el-image class="imgbox" :src="comData.com_url"></el-image>
            </div>
            <!-- 信息 -->
            <div class="talentInfo">
              <div class="contact">
                <p>
                  {{ comData.com_external_name }}
                  <img :src="vipIcon" alt="" />
                </p>
              </div>
              <div class="contact">
                <span>浏览量：{{ comData.see_number }}</span>
              </div>
              <div class="contact">
                <span>
                  分享到：
                  <img class="mini-icon" :src="wxIcon" alt="" />
                  <img class="mini-icon" :src="qqIcon" alt="" />
                  <img class="mini-icon" :src="wbIcon" alt="" />
                  <img class="mini-icon" :src="kjIcon" alt="" />
                </span>
              </div>
            </div>
            <div class="down"></div>
          </div>
        </div>
      </section>
    </div>
    <!--  -->
    <section>
      <el-row :gutter="20" style="margin-top: 30px;">
        <el-col
          :span="16"
          style="padding: 20px;background: #fff;border-radius: 6px"
        >
          <el-tabs v-model="tabName" @tab-click="changetab">
            <el-tab-pane label="公司简介" name="first">
              <h4 style="font-size: 17px"><b>公司简介</b></h4>
              <p style="margin-bottom: 30px">{{ comData.com_synopsis }}</p>
              <h4 style="font-size: 17px"><b>代办范围</b></h4>
              <p style="margin-bottom: 30px">{{ comData.com_commission }}</p>
              <h4 style="font-size: 17px"><b>精英团队</b></h4>
              <p>
                <el-row :gutter="16">
                  <el-col
                    :span="12"
                    v-for="(item, index) in comuserList"
                    :key="index"
                    style="margin-bottom: 16px"
                  >
                    <div class="groupBox clearfix">
                      <el-image
                        class="userimg"
                        :src="item.u_portrait_url"
                      ></el-image>
                      <div class="g-infoBox">
                        <div class="g-info">
                          <span>联系人: {{ item.u_name }}</span>
                        </div>
                        <div class="g-info">
                          <span>联系电话: {{ item.u_phone }}</span>
                        </div>
                        <div class="g-info">
                          <span>简历反馈率: {{ item.resume_rate }}%</span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </p>
              <h4 style="font-size: 17px"><b>公司地址</b></h4>
              <p style="margin-bottom: 30px">
                {{ comData.com_province }}-{{ comData.com_city }}
                {{ comData.com_address }}
              </p>
            </el-tab-pane>
            <el-tab-pane label="招聘职位" name="second">
              <!-- 发布职位信息 -->
              <div class="tableBox">
                <div style="padding: 10px">
                  <el-radio-group
                    v-model="workType"
                    size="mini"
                    @change="changeWorkType"
                  >
                    <el-radio-button label="1">兼职</el-radio-button>
                    <el-radio-button label="2">全职</el-radio-button>
                  </el-radio-group>
                </div>
                <!-- 显示中的信息 -->
                <el-table :data="tableInfo1" class="partTimeTable">
                  <el-table-column width="570px">
                    <template slot-scope="scope">
                      <div class="leftPadding">
                        <div class="cername">
                          <span
                            class="fontHover"
                            v-if="workType == 1"
                            @click="gopartTime(scope.row)"
                          >
                            {{ scope.row.j_certificate }}-{{
                              scope.row.j_major
                            }}
                          </span>
                          <span
                            class="fontHover"
                            v-if="workType == 2"
                            @click="gofullTime(scope.row)"
                          >
                            {{ scope.row.j_certificate }}-{{
                              scope.row.j_major
                            }}
                          </span>
                          <b class="smallFont">
                            <b v-if="workType == 1">
                              {{ scope.row.j_area_province }}-{{
                                scope.row.j_area_city
                              }}
                            </b>
                            <b v-if="workType == 2">
                              {{ scope.row.j_province }}-{{ scope.row.j_city }}
                            </b>
                          </b>
                        </div>
                        <div class="cerstate">
                          <span class="cerPay">
                            {{ scope.row.j_min_price }}万-{{
                              scope.row.j_max_price
                            }}万<span v-if="workType == 1"
                              >/{{ scope.row.j_sign_time }}</span
                            >
                          </span>
                          <span>{{ scope.row.j_register }}</span>
                          <span>{{ scope.row.j_purpose }}</span>
                          <span>
                            <span v-if="scope.row.j_social_need == '不限'">
                              社保</span
                            >{{ scope.row.j_social_need }}
                          </span>
                        </div>
                        <div class="cerstate">
                          <span>{{ scope.row.j_sign_method }}</span>
                          <span>{{ scope.row.j_pay_method }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <div class="leftPadding">
                        <div class="avatarBox" style="text-align: right;">
                          <span>
                            <el-avatar :size="24" style="background:#fff;">
                              <el-image :src="scope.row.u_portrait">
                                <div slot="error" class="image-slot">
                                  <i
                                    class="el-icon-picture-outline error-img-24"
                                  ></i>
                                </div>
                              </el-image>
                            </el-avatar>
                          </span>
                          <span style="font-weight: 600;color: #000;">{{
                            scope.row.u_names
                          }}</span>
                          <!-- <span style="float:right;padding-top: 6px;">
                            <span style="color: #999">专业能力：</span>
                            <span style="color: #f00">{{
                              scope.row.professionalism
                            }}</span>
                          </span> -->
                        </div>
                        <div style="text-align: right;" class="overflowOmit">
                          <span>{{ scope.row.com_external_name }}</span>
                        </div>
                        <div style="text-align: right;">
                          <span style="color: #2573F1;">{{
                            scope.row.create_time
                          }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <div slot="empty" class="empty">
                    <div>
                      <img :src="noDate" />
                    </div>
                    <span>暂无数据</span>
                  </div>
                </el-table>
                <div class="pageBox">
                  <el-pagination
                    background
                    @size-change="sizeChange1"
                    @current-change="currentChange1"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="求职信息" name="third">
              <el-table :data="tableInfo2" class="partTimeTable tableHover">
                <el-table-column width="100px">
                  <template slot-scope="scope">
                    <div
                      style="padding-left:10px"
                      @click="gorecomme(scope.row, scope.row.uid)"
                    >
                      <span style="font-weight:700" class="fontHover">
                        {{ scope.row.tal_name.slice(0, 1) }}工
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="200px">
                  <template slot-scope="scope">
                    <div
                      class="text-center"
                      @click="gorecomme(scope.row, scope.row.uid)"
                    >
                      <span>
                        {{ scope.row.certificate }}-{{ scope.row.major }}
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="330px">
                  <template slot-scope="scope">
                    <div
                      class="partType"
                      @click="gorecomme(scope.row, scope.row.uid)"
                    >
                      <span>{{ scope.row.zs_price }}万</span>
                      <span>{{ scope.row.register }}</span>
                      <span
                        >{{ scope.row.expected_province }}-{{
                          scope.row.expected_city
                        }}Q</span
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <div
                      class="text-right"
                      @click="gorecomme(scope.row, scope.row.uid)"
                      style="padding-right:10px"
                    >
                      <span style="color: #2573F1">{{
                        scope.row.create_time
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <div slot="empty" class="empty">
                  <div>
                    <img :src="noDate" />
                  </div>
                  <span>暂无数据</span>
                </div>
              </el-table>
              <div class="pageBox">
                <el-pagination
                  background
                  @size-change="sizeChange2"
                  @current-change="currentChange2"
                  :current-page="currentPage2"
                  :page-sizes="[10, 20, 30, 40, 50]"
                  :page-size="pagesize2"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="tableTotal2"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card">
            <div slot="header">
              <span class="cardTitle">基本信息</span>
            </div>
            <div class="buildBox margin-bottom-16">
              <div>
                <span
                  >联系人：{{
                    comData.group_user_info.length == 0
                      ? ""
                      : comData.group_user_info[0].u_name
                  }}</span
                >
              </div>
              <div>
                <span
                  >QQ号：{{
                    comData.group_user_info.length == 0
                      ? ""
                      : comData.group_user_info[0].qq
                  }}</span
                >
              </div>
              <div>
                <span
                  >手机号：{{
                    comData.group_user_info.length == 0
                      ? ""
                      : comData.group_user_info[0].u_phone
                  }}</span
                >
              </div>
              <div>
                <span
                  >固话：{{
                    comData.group_user_info.length == 0
                      ? ""
                      : comData.group_user_info[0].line
                  }}</span
                >
              </div>
              <div>
                <span
                  >邮箱：{{
                    comData.group_user_info.length == 0
                      ? ""
                      : comData.group_user_info[0].email
                  }}</span
                >
              </div>
              <div>
                <span
                  >地址：{{ comData.com_province }}-{{ comData.com_city }}
                  {{ comData.com_address }}</span
                >
              </div>
              <div>
                <span>网址：{{ comData.com_website }}</span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
export default {
  name: "comDetail",
  data() {
    return {
      vipIcon: require("@/assets/icon/vip.png"),
      kjIcon: require("@/assets/icon/shell-kj.png"),
      qqIcon: require("@/assets/icon/shell-qq.png"),
      wxIcon: require("@/assets/icon/shell-wx.png"),
      wbIcon: require("@/assets/icon/shell-wb.png"),
      noDate: require("@/assets/img/noDate.png"),
      comData: {},
      tabName: "first",
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      currentPage2: 1,
      pagesize2: 10,
      tableTotal2: 0,
      searchInfo: {},
      searchInfo2: {},
      workType: "1",
      tableInfo1: [],
      tableInfo2: [],
      comuserList: [],
    };
  },
  methods: {
    getComData() {
      let info = { com_id: this.$route.query.comid };
      this.$http.post("index/parjob/companydetails", info).then((res) => {
        if (res.data.resultCode === 1) {
          this.comData = res.data.resultData[0];
          this.comuserList = res.data.resultData[0].group_user_info;
          this.getParttimeList();
        }
      });
    },
    // 切换tab
    changetab() {},
    // 获取职位显示中信息  table
    getTableData1() {
      this.searchInfo.page = this.currentPage;
      this.searchInfo.limit = this.pagesize;

      this.searchInfo.j_company_id = this.$route.query.comid;
      if (this.workType == 1) {
        this.$http.post("index/parjob/getlist", this.searchInfo).then((res) => {
          this.tableInfo1 = res.data.resultData.list;
          this.dataLength = res.data.resultData.count;
          this.tableTotal = parseInt(res.data.resultData.count);
        });
      }
      if (this.workType == 2) {
        this.$http
          .post("index/fulljob/getlist", this.searchInfo)
          .then((res) => {
            this.tableInfo1 = res.data.resultData.list;
            this.dataLength = res.data.resultData.count;
            this.tableTotal = parseInt(res.data.resultData.count);
          });
      }
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getTableData1(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getTableData1(e, this.pagesize);
    },
    changeWorkType() {
      this.getTableData1();
    },
    // 获取人员兼职列表
    getParttimeList() {
      console.log(this.comData.type);
      if (this.comData.type == 2) {
        this.searchInfo2.page = this.currentPage;
        this.searchInfo2.limit = this.pagesize;
        this.searchInfo2.com_id = this.$route.query.comid;
        this.$http
          .post("index/resume/parresume", this.searchInfo2)
          .then((res) => {
            if (res.data.resultCode === 1) {
              this.tableInfo2 = res.data.resultData.list;
              this.tableTotal2 = parseInt(res.data.resultData.count);
            }
          });
      }
      if (this.comData.type == 3) {
        this.searchInfo2.page = this.currentPage;
        this.searchInfo2.limit = this.pagesize;
        this.searchInfo2.com_id = this.$route.query.comid;
        this.$http
          .post("index/resume/recommend", this.searchInfo2)
          .then((res) => {
            if (res.data.resultCode === 1) {
              this.tableInfo2 = res.data.resultData.list;
              this.tableTotal2 = parseInt(res.data.resultData.count);
            }
          });
      }
    },
    sizeChange2(e) {
      this.pagesize = e;
      this.currentPage2 = 1;
      this.getParttimeList(this.currentPage2, this.pagesize2);
    },
    currentChange2(e) {
      this.currentPage2 = e;
      this.getParttimeList(e, this.pagesize2);
    },
    gopartTime(item) {
      let routeUrl = this.$router.resolve({
        name: "partTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
    gofullTime(item) {
      let routeUrl = this.$router.resolve({
        name: "fullTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
    gorecomme(item, uid) {
      let routeUrl = this.$router.resolve({
        name: "recommeInfo",
        query: {
          id: item.id,
          uid: uid,
          cer: item.certificate,
          major: item.major,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    addLook() {
      this.$http.post("index/fulljob/seenumber", {
        j_company_id: this.$route.query.comid,
      });
    },
  },
  mounted() {
    this.getComData();
    this.getTableData1();
    this.addLook();
  },
};
</script>
<style scoped>
.imgbox {
  width: 100px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 6px;
}
.topboxShadow {
  margin-top: -24px;
  background: #fff;
  box-shadow: 0px 5px 9px -2px rgba(173, 193, 213, 0.5);
}
.resumeTitle {
  padding: 20px 0;
}
.talentIndex {
  padding-top: 24px;
}
.demonstration {
  margin-top: 10px;
}
.talentAvd {
  display: block;
  float: left;
}
.talentAvd.block {
  padding: 20px 20px 0px;
}
.talentInfo {
  display: block;
  float: left;
  width: calc(100% - 330px);
  padding: 28px 0px;
}
.talentInfo .name {
  font-size: 16px;
  display: inline-block;
}
.talentInfo .baseInfo span {
  display: inline-block;
  padding: 4px 16px;
}
.talentInfo .baseInfo span:first-child {
  padding-left: 0;
}
.contact p {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 7px;
}
.contact > span {
  padding: 7px 0;
  display: inline-block;
  color: #999;
}
.contact span img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.contact > span {
  padding-right: 20px;
}
.groupBox {
  background-color: #e9f1fd;
  padding: 20px;
  border-radius: 4px;
}
.userimg {
  width: 80px;
  height: 80px;
  float: left;
  margin-right: 10px;
}
.g-infoBox {
  float: left;
}
.g-info span {
  color: #999;
  padding: 5px 0px 5px 0;
  display: inline-block;
}
.down {
  text-align: right;
  padding-right: 20px;
  padding-top: 40px;
}
/deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
}
.cername span {
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  color: #333;
}
.leftPadding {
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.cerstate {
  padding-top: 8px;
  line-height: 1;
}
.cerstate span {
  color: #999;
}
.cerstate span:not(:last-child) {
  margin-right: 24px;
}
.cerstate .cerPay {
  font-size: 17px;
  font-weight: bold;
  color: #ff0000;
}
.avatarBox {
  line-height: 12px;
}
.avatarBox > span:first-child {
  vertical-align: middle;
  margin-right: 12px;
}
.tr:hover {
  box-shadow: 0px 5px 14px 6px rgba(173, 193, 213, 0.5);
}
.smallFont {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
}
.hotworkBtn:hover {
  color: #2573f1;
}
.hotworkBtn {
  color: #333;
}
.cardTitle {
  font-size: 17px;
  font-weight: bold;
}
.buildBox > div {
  color: #999999;
  line-height: 30px;
}
</style>
